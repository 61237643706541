import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  Typography,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useSignupStore from '../../store/signup';
import api from '../../lib/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    textAlign: 'center',
    maxWidth: 400,
    width: '100%',
    minHeight: '500px',
    alignContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  inputField: {
    width: '100%',
    marginRight: '0px',
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  stepIndicator: {
    marginTop: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(0.5),
    marginLeft: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
}));

const Step1 = ({ handleNextStep }) => {
  const classes = useStyles();
  const {
    profileData,
    setProfileData,
    isProfileSubmitted,
    setProfileSubmitted,
  } = useSignupStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  // Initialize phone number as an empty string so placeholder is visible
  const [phone, setPhone] = useState(profileData.phone || '');

  // Add +1 when input is focused and empty
  const handlePhoneFocus = () => {
    if (phone === '') {
      setPhone('+1 ');
    }
  };

  const handlePhoneInputChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');

    if (input.startsWith('1')) {
      input = input.slice(1);
    }

    if (input.length > 10) {
      input = input.slice(0, 10);
    }

    let formattedPhone = '+1 ';
    if (input.length > 0) {
      formattedPhone += input.slice(0, 3);
    }
    if (input.length >= 4) {
      formattedPhone += ' ' + input.slice(3);
    }

    setPhone(formattedPhone);
    setProfileData({ ...profileData, phone: formattedPhone });
  };

  // Updated validateForm function to check for character limit
  const validateForm = () => {
    const errors = {};
    const maxLengths = {
      first_name: 30,
      last_name: 30,
      company: 65,
      legalEntity: 65,
    };

    // Check for required fields and character limits
    if (!profileData.first_name) {
      errors.first_name = 'First Name is required';
    } else if (profileData.first_name.length > maxLengths.first_name) {
      errors.first_name = `Character Limit Exceeded. Limit: ${maxLengths.first_name}`;
    }

    if (!profileData.last_name) {
      errors.last_name = 'Last Name is required';
    } else if (profileData.last_name.length > maxLengths.last_name) {
      errors.last_name = `Character Limit Exceeded. Limit: ${maxLengths.last_name}`;
    }

    if (!profileData.company) {
      errors.company = 'Company Display Name is required';
    } else if (profileData.company.length > maxLengths.company) {
      errors.company = `Character Limit Exceeded. Limit: ${maxLengths.company}`;
    }

    if (!profileData.legalEntity) {
      errors.legalEntity = 'Legal Entity is required';
    } else if (profileData.legalEntity.length > maxLengths.legalEntity) {
      errors.legalEntity = `Character Limit Exceeded. Limit: ${maxLengths.legalEntity}`;
    }

    const phoneRegex = /^\+1 \d{3} \d{7}$/; // Validate format "+1 xxx xxxxxxx"
    if (!phoneRegex.test(profileData.phone)) {
      errors.phone =
        'Please enter a valid phone number in the format +1 xxx xxxxxxx';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const payload = {
      account: profileData.company,
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      phone: profileData.phone,
      legal_name: profileData.legalEntity,
    };

    try {
      if (!isProfileSubmitted) {
        await api.post('/api/users/custom-provider-profile/', payload);
        setProfileSubmitted(true);
      } else {
        await api.patch('/api/users/custom-provider-profile/', payload);
      }
      handleNextStep();
    } catch (err) {
      setError('Failed to save your information. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h5">Tell us about yourself</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.first_name)}
          >
            <Input
              id="first-name-input"
              placeholder="First Name"
              disableUnderline
              fullWidth
              value={profileData.first_name || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, first_name: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 30 }} // Set maxLength for first name
            />
            {validationErrors.first_name && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.first_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.last_name)}
          >
            <Input
              id="last-name-input"
              placeholder="Last Name"
              disableUnderline
              fullWidth
              value={profileData.last_name || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, last_name: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 30 }} // Set maxLength for last name
            />
            {validationErrors.last_name && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.last_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.company)}
          >
            <Input
              id="company-name-input"
              placeholder="Company Display Name"
              disableUnderline
              fullWidth
              value={profileData.company || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, company: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 65 }} // Set maxLength for company
            />
            {validationErrors.company && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.company}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.legalEntity)}
          >
            <Input
              id="legal-entity-input"
              placeholder="Legal Entity"
              disableUnderline
              fullWidth
              value={profileData.legalEntity || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, legalEntity: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 65 }} // Set maxLength for legal entity
            />
            {validationErrors.legalEntity && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.legalEntity}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.phone)}
          >
            <Input
              id="phone-input"
              placeholder="Phone Number"
              disableUnderline
              fullWidth
              value={phone}
              onFocus={handlePhoneFocus}
              onChange={handlePhoneInputChange}
              className={classes.inputField}
              inputProps={{ maxLength: 14 }}
            />
            {validationErrors.phone && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.phone}
              </FormHelperText>
            )}
          </FormControl>

          {loading ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Next
            </Button>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <Typography className={classes.stepIndicator}>Step 1 of 5</Typography>
        </form>
      </div>
    </div>
  );
};

export default Step1;
