import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab'; // Import Autocomplete
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import useSignupStore from '../../store/signup';
import api from '../../lib/api';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    textAlign: 'center',
    maxWidth: 400,
    width: '100%',
    height: '500px', // Fixed height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  serviceContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
    height: '100px',
    overflowY: 'auto', // Enable vertical scrolling
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  previousButton: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  nextButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  stepIndicator: {
    marginTop: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  inputBox: {
    marginRight: 0,
  },
}));

const Step3 = ({ handleNextStep, handlePreviousStep }) => {
  const classes = useStyles();
  const {
    services: storedServices,
    setServices,
    fundingSources,
  } = useSignupStore();
  const [availableServices, setAvailableServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(storedServices);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get('/api/users/provider/service-type/', {
          params: {
            funding_sources: fundingSources.join(','),
          },
        });
        setAvailableServices(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching services:', error);
        setLoading(false);
      }
    };

    fetchServices();
  }, [fundingSources]);

  useEffect(() => {
    setSelectedServices(storedServices);
  }, [storedServices]);

  const handleAddService = (event, newValue) => {
    if (newValue && !selectedServices.some((s) => s.id === newValue.id)) {
      setSelectedServices((prev) => [...prev, newValue]);
    }
  };

  const handleRemoveService = (service) => {
    setSelectedServices((prev) => prev.filter((s) => s.id !== service.id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        service_type_ids: selectedServices.map((service) => service.id),
      };
      await api.patch('/api/users/provider/service-type/', payload);
      setServices(selectedServices);
      handleNextStep();
    } catch (error) {
      console.error('Error during service submission:', error);
    }
  };

  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h5">Select the Services You Offer</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Autocomplete
            options={availableServices}
            getOptionLabel={(option) => option.name}
            onChange={handleAddService}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search for or select a service"
                variant="outlined"
                className={classes.inputBox}
              />
            )}
          />

          <Box className={classes.serviceContainer}>
            {selectedServices.map((service) => (
              <Box
                key={service.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>{service.name}</Typography>
                <IconButton
                  onClick={() => handleRemoveService(service)}
                  size="small"
                >
                  <Close />
                </IconButton>
              </Box>
            ))}
          </Box>

          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              className={classes.previousButton}
              onClick={handlePreviousStep}
            >
              Previous
            </Button>
            <Button
              type="submit"
              variant="contained"
              className={classes.nextButton}
              disabled={selectedServices.length === 0}
            >
              Next
            </Button>
          </div>
          <Typography className={classes.stepIndicator}>Step 3 of 5</Typography>
        </form>
      </div>
    </div>
  );
};

export default Step3;
