import axios from 'axios';
import useAuthStore from '../store/auth';
import Auth from './Auth';
import useSignupStore from '../store/signup';

const authUrls = [
  '/auth/login/',
  '/auth/token/refresh/',
  '/auth/password/reset/',
];

const refreshAccessToken = async () => {
  try {
    console.log('Attempting to refresh access token');

    const refreshToken =
      Auth.refreshToken || localStorage.getItem('signup_refreshToken');
    const res = await ax.post('/auth/token/refresh/', {
      refresh: refreshToken,
    });

    const access = res.data.access;
    console.log('Access token refreshed successfully:', access);

    // Save the new access token in both Auth and signupStore
    if (Auth.refreshToken) {
      Auth.accessToken = access;
    } else if (localStorage.getItem('signup_refreshToken')) {
      useSignupStore.getState().setAuthToken(access);
    }

    return access;
  } catch (e) {
    console.error('Failed to refresh access token. Error:', e);

    // Explicitly log which refresh token was used
    console.log(
      'Refresh token used:',
      Auth.refreshToken || localStorage.getItem('signup_refreshToken')
    );

    // Only clear user state when the refresh truly fails
    useAuthStore.setState({
      user: null,
      authenticated: false,
      checkingAuth: false,
    });
    return null;
  }
};

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ax.interceptors.request.use((config) => {
  if (authUrls.includes(config.url)) {
    return config;
  }

  const { authToken } = useSignupStore.getState(); // Get the token from signupStore
  const accessToken =
    authToken || Auth.accessToken || localStorage.getItem('signup_accessToken'); // Use either signup or global token

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  // Only set Content-Type to application/json if the request data is not FormData
  if (!(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: originalRequest, response } = error;

    if (response?.status === 503) {
      window.location.href = '/503';
    }

    if (
      originalRequest.url !== '/auth/token/refresh/' &&
      !originalRequest._retry &&
      response?.status === 401
    ) {
      originalRequest._retry = true;
      const access = await refreshAccessToken();

      if (access) {
        ax.defaults.headers.common['Authorization'] = `Bearer ${access}`;
        return ax(originalRequest);
      } else {
        // Token refresh failed, perform a proper logout
        Auth.logout();
        useAuthStore.setState({
          user: null,
          authenticated: false,
          checkingAuth: false,
        });
      }
    }

    return Promise.reject(error);
  }
);

export default ax;
