const Auth = {
  _accessKey: 'ACCESS_TOKEN',
  _refreshKey: 'REFRESH_TOKEN',
  _currentUserKey: 'CURRENT_USER',

  get accessToken() {
    return localStorage.getItem(Auth._accessKey);
  },

  set accessToken(token) {
    localStorage.setItem(Auth._accessKey, token);
  },

  clearAccessToken() {
    localStorage.removeItem(Auth._accessKey); // Changed from `clear` to `removeItem`
  },

  get refreshToken() {
    return localStorage.getItem(Auth._refreshKey);
  },

  set refreshToken(token) {
    localStorage.setItem(Auth._refreshKey, token);
  },

  clearRefreshToken() {
    localStorage.removeItem(Auth._refreshKey); // Changed from `clear` to `removeItem`
  },

  get currentUser() {
    const userString = localStorage.getItem(Auth._currentUserKey);
    return userString ? JSON.parse(userString) : null;
  },

  set currentUser(user) {
    localStorage.setItem(Auth._currentUserKey, JSON.stringify(user));
  },

  clearCurrentUser() {
    localStorage.removeItem(Auth._currentUserKey); // Changed from `clear` to `removeItem`
  },

  clearParams() {
    localStorage.removeItem('careRequestsDetailsViewParams');
  },

  // General logout method that clears tokens and user data
  logout() {
    Auth.clearAccessToken();
    Auth.clearRefreshToken();
    Auth.clearCurrentUser();
    Auth.clearParams();
  },

  // Signup-specific logout method that does NOT clear tokens
  signupLogout() {
    Auth.clearCurrentUser(); // Clear user data, but keep tokens
    Auth.clearParams();
  },
};

export default Auth;
