import create from 'zustand';
import { devtools } from 'zustand/middleware';
import Auth from '../lib/Auth';

const signupStore = (set) => ({
  profileData: JSON.parse(localStorage.getItem('signup_profileData')) || {}, // Store profile data
  fundingSources:
    JSON.parse(localStorage.getItem('signup_fundingSources')) || [], // Store funding sources
  services: JSON.parse(localStorage.getItem('signup_services')) || [], // Store services
  counties: JSON.parse(localStorage.getItem('signup_counties')) || [], // Store counties
  documents: JSON.parse(localStorage.getItem('signup_documents')) || [], // Store uploaded documents
  authToken: localStorage.getItem('signup_accessToken') || '', // Store access token from signup store
  refreshToken: localStorage.getItem('signup_refreshToken') || '', // Store refresh token from signup store
  userId: localStorage.getItem('userId') || '', // Store user ID
  documentStatus: localStorage.getItem('signup_documentStatus') || '', // Store the overall status of document approval
  signupStep: parseInt(localStorage.getItem('signup_step'), 10) || 0, // Store the current signup step
  isProfileSubmitted:
    JSON.parse(localStorage.getItem('isProfileSubmitted')) || false, // Persist isProfileSubmitted
  isProfileCompleted:
    JSON.parse(localStorage.getItem('isProfileCompleted')) || false, // Persist isProfileCompleted, defaults to false

  // Actions to set data
  setProfileData: (data) => {
    set({ profileData: data });
    if (Object.keys(data).length > 0) {
      localStorage.setItem('signup_profileData', JSON.stringify(data)); // Save to localStorage only if data is not empty
    }
  },
  setFundingSources: (sources) => {
    set({ fundingSources: sources });
    if (sources.length > 0) {
      localStorage.setItem('signup_fundingSources', JSON.stringify(sources)); // Save to localStorage only if sources are not empty
    }
  },
  setServices: (services) => {
    set({ services: services });
    if (services.length > 0) {
      localStorage.setItem('signup_services', JSON.stringify(services)); // Save to localStorage only if services are not empty
    }
  },
  setCounties: (counties) => {
    set({ counties: counties });
    if (counties.length > 0) {
      localStorage.setItem('signup_counties', JSON.stringify(counties)); // Save to localStorage only if counties are not empty
    }
  },
  setDocuments: (documents) => {
    set({ documents: documents });
    if (documents.length > 0) {
      localStorage.setItem('signup_documents', JSON.stringify(documents)); // Save to localStorage only if documents are not empty
    }
  },

  // Actions to set tokens and user ID, including saving them to localStorage
  setAuthToken: (token) => {
    set({ authToken: token });
    if (token) {
      localStorage.setItem('signup_accessToken', token); // Store access token directly in localStorage
    }
  },
  setRefreshToken: (token) => {
    set({ refreshToken: token });
    if (token) {
      localStorage.setItem('signup_refreshToken', token); // Store refresh token directly in localStorage
    }
  },
  setUserId: (id) => {
    set({ userId: id });
    if (id) {
      localStorage.setItem('userId', id); // Store user ID in localStorage only if id is not empty
    }
  },

  // Action to set document status
  setDocumentStatus: (status) => {
    set({ documentStatus: status });
    if (status) {
      localStorage.setItem('signup_documentStatus', status); // Save to localStorage only if status is not empty
    }
  },

  // Action to set the current signup step
  setSignupStep: (step) => {
    set({ signupStep: step });
    localStorage.setItem('signup_step', step.toString());
  },
  setProfileSubmitted: (value) => {
    set({ isProfileSubmitted: value });
    localStorage.setItem('isProfileSubmitted', JSON.stringify(value)); // Persist to localStorage
  },

  // Set isProfileCompleted state and save it to localStorage
  setProfileCompleted: (value) => {
    set({ isProfileCompleted: value });
    localStorage.setItem('isProfileCompleted', JSON.stringify(value)); // Persist to localStorage
  },

  // Clear all stored signup data both in Zustand and localStorage
  clearSignupData: () => {
    localStorage.removeItem('signup_profileData');
    localStorage.removeItem('signup_fundingSources');
    localStorage.removeItem('signup_services');
    localStorage.removeItem('signup_counties');
    localStorage.removeItem('signup_documents');
    localStorage.removeItem('signup_documentStatus');
    localStorage.removeItem('signup_step');
    localStorage.removeItem('signup_email');
    localStorage.removeItem('signup_password');
    localStorage.removeItem('signup_accessToken');
    localStorage.removeItem('signup_refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('isProfileSubmitted');
    localStorage.removeItem('isProfileCompleted');
  },

  completeSignup: () => {
    set({
      profileData: {},
      fundingSources: [],
      services: [],
      counties: [],
      documents: [],
      authToken: '',
      refreshToken: '',
      userId: '',
      documentStatus: '',
      signupStep: 0,
      isProfileSubmitted: false,
      isProfileCompleted: false,
    });
    useSignupStore.getState().clearSignupData();
  },

  loadTokensFromStorage: () => {
    const accessToken =
      Auth.accessToken || localStorage.getItem('signup_accessToken');
    const refreshToken =
      Auth.refreshToken || localStorage.getItem('signup_refreshToken');

    if (accessToken) {
      set({ authToken: accessToken });
    }
    if (refreshToken) {
      set({ refreshToken: refreshToken });
    }
  },
});

const useSignupStore = create(devtools(signupStore));

export default useSignupStore;
